var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"items"}},[_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('CCard',[_c('CCardHeader',{staticClass:"d-flex justify-content-between align-items-center"},[_c('h4',{staticClass:"mb-0"},[_vm._v("Veículos")]),_c('div',{staticClass:"d-flex"},[(_vm.datatable.items && _vm.datatable.items.length > 0)?_c('router-link',{staticClass:"btn mr-3 d-flex align-items-center btn-secondary",attrs:{"to":"/vehicles/order"}},[_c('CIcon',{staticClass:"mr-2",attrs:{"name":"cil-list-high-priority"}}),_vm._v(" Ordenar ")],1):_vm._e(),_c('router-link',{staticClass:"btn btn-primary d-flex align-items-center",attrs:{"to":"/vehicles/create"}},[_c('CIcon',{staticClass:"mr-2",attrs:{"name":"cil-library-add"}}),_vm._v(" Incluir ")],1)],1)]),_c('CCardBody',[_c('CRow',[_c('CCol',{attrs:{"col":"12"}},[_c('Search',{on:{"search":_vm.updateSearch}})],1)],1),_c('CRow',[_c('CCol',{attrs:{"col":"12"}},[_c('CRow',[_c('CCol',{attrs:{"md":"4","lg":"4","xl":"3"}},[_c('div',{staticClass:"form-group"},[_c('Multiselect',{attrs:{"value":_vm.statusOptions.find(
                          function (ref) {
                                                  var value = ref.value;

                                                  return value === _vm.query.status;
}
                        ),"options":_vm.statusOptions},on:{"select":function (ref) {
                                                var value = ref.value;

                                                return (_vm.query.status = value);
}}})],1)]),_c('CCol',{attrs:{"md":"4","lg":"4","xl":"3"}},[_c('div',{staticClass:"form-group"},[_c('Multiselect',{attrs:{"value":_vm.modelsOptions.find(
                          function (ref) {
                                                  var value = ref.value;

                                                  return value === _vm.query.model;
}
                        ),"options":_vm.modelsOptions},on:{"select":function (ref) {
                                                var value = ref.value;

                                                return (_vm.query.model = value);
}}})],1)])],1)],1)],1),_c('CRow',[_c('CCol',{attrs:{"col":"12"}},[_c('DataTable',{attrs:{"sorter":_vm.query.sorter,"items":_vm.datatable.items,"fields":_vm.datatable.fields,"enable-export":false,"base-route":"/vehicles/edit/"},on:{"sorterValue":function (event) { return (_vm.query.sorter = event); },"row-clicked":_vm.handleRowClick,"update:checked":_vm.updateStatus},scopedSlots:_vm._u([{key:"actions",fn:function(ref){
                        var id = ref.id;
return [_c('DatatableEdit',{attrs:{"url":("/vehicles/edit/" + id)}}),_c('DatatableRemove',{on:{"row-clicked":_vm.handleRowClick,"deleted":_vm.deleteItem}})]}}])})],1)],1),_c('CRow',{staticClass:"mt-2"},[_c('CCol',{attrs:{"lg":"12"}},[_c('Pagination',{attrs:{"page":_vm.query.page,"per-page":_vm.query.perpage,"pages":_vm.pagination.pages,"total":_vm.pagination.total,"items":_vm.pagination.items},on:{"update:active-page":function (page) { return (_vm.query.page = page); },"update:perpage":function (perpage) { return (_vm.query.perpage = perpage); }}})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }